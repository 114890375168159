import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Skeleton, Divider, List, Collapse, Alert, ListItem, ListItemText } from '@mui/material';
import { getVersion as getBookingsVersion } from '@eatclub-apps/ec-bookings-library';
import { Box } from '@eatclub-apps/ec-component-library';
import { useTheme } from '@mui/material/styles';
import { restaurantActivePropTypes } from '../../../data/models/Restaurant';
import { overrideDisabledRestaurants } from '../../../utils/flags';
import { findObjectByProperty, getVersionText } from '../../../utils/helpers';
import useStyles from './NavigationDrawerStyles';
import DownArrowSVG from '../../../assets/down_caret.svg';
import HideNavigationSVG from '../../../assets/hide_navigation.svg';
import RestaurantSwitcher from './RestaurantSwitcher/RestaurantSwitcher';
import { COLORS } from '../../../EatClubTheme';
import { userPropTypes } from '../../../data/models/User';

const NavigationDrawer = ({
  hideDrawer,
  navigationItems,
  allowHideNavigation,
  user,
  restaurantFetching,
  restaurantActive,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const [collapsibles, setCollapsibles] = useState([]);

  // Check whether a nav item is selected (we're on that page)
  const isSelected = (slug) => {
    return location?.pathname === slug || location?.pathname.startsWith(slug);
  };

  // Set up the navigation collapsibles
  useEffect(() => {
    const navsWithSubItems = navigationItems.filter((nav) => nav?.subItems?.length > 0);
    const navigationCollapsibles = navsWithSubItems?.map((nav) => {
      // Also, if we're on the selected screen, open the collapsible
      const hasSelectedNav =
        nav?.subItems?.filter((subItem) => {
          return isSelected(`/${subItem?.slug?.toLowerCase().replace(/ /g, '-')}`);
        }).length > 0;

      return {
        id: nav?.name,
        value: hasSelectedNav, // Whether it is open
      };
    });

    setCollapsibles(navigationCollapsibles);
  }, [navigationItems]);

  /**
   * Toggle a collapsible nav item on or off
   */
  const toggleCollapsible = (name) => {
    const newCollapsibles = collapsibles?.map((collapsible) => {
      if (collapsible?.id === name) {
        return { ...collapsible, value: !collapsible?.value };
      }

      return collapsible;
    });

    setCollapsibles(newCollapsibles);
  };

  const getNavLink = (slug) => `/${slug.toLowerCase().replace(/ /g, '-')}`;

  const loadingItem = (key) => (
    <Skeleton height={42} width={260} style={{ margin: 'auto', borderRadius: '12px' }} key={key} />
  );

  const isStaff = user.userInfo?.userType === 'staff';

  return (
    <Box className={classes.sidebar}>
      <Box className={classes.sidebarNavigation}>
        <Box>
          <RestaurantSwitcher />
          {isStaff && (
            <>
              <Box style={{ marginTop: '8px' }}>
                <Alert
                  severity='info'
                  style={{ borderRadius: '20px', backgroundColor: '#E3EBF6' }}
                >{`Logged in as ${user.userInfo.name}`}</Alert>
              </Box>

              {restaurantActive?.restaurant?.objectId &&
                !restaurantActive?.restaurant?.enabled &&
                !overrideDisabledRestaurants.includes(restaurantActive?.restaurant?.objectId) && (
                  <Box style={{ marginTop: '8px' }}>
                    <Alert
                      severity='info'
                      style={{ borderRadius: '20px', backgroundColor: '#E3EBF6' }}
                    >
                      This restaurant is temporarily disabled
                    </Alert>
                  </Box>
                )}

              <Divider className={classes.divider} />
              {/* <Divider className={classes.divider} style={{ margin: '10px 20px', backgroundColor: COLORS.SHARK }} /> */}
            </>
          )}
          <List className={classes.list}>
            {navigationItems.map((nav) => {
              if (nav.section !== 1) {
                return <React.Fragment key={nav?.name} />;
              }

              if (nav?.subItems?.length > 0) {
                if (restaurantFetching && !isStaff) {
                  return loadingItem(nav?.name);
                }

                const open = findObjectByProperty(collapsibles, nav?.name, 'id')?.value === true;

                const selected = nav.subItems.some((subItem) => {
                  const subNavLink = getNavLink(subItem?.slug);
                  return isSelected(subNavLink);
                });

                return (
                  <Box key={nav?.name}>
                    <Box
                      key={nav.name}
                      onClick={() => {
                        toggleCollapsible(nav?.name);
                      }}
                      /* to='#' */
                      className={classes.navigationLink}
                      style={{
                        color: selected ? '#000000' : '#6E6E6D',
                      }}
                    >
                      {nav.name}

                      <DownArrowSVG
                        className={classes.expandArrow}
                        style={{
                          transform: open ? 'rotate(180deg)' : '',
                        }}
                      />
                    </Box>

                    <Collapse
                      in={open}
                      timeout='auto'
                      style={{ transitionDuration: '0.5s' }}
                      unmountOnExit
                    >
                      <List component='div' disablePadding>
                        {nav.subItems?.map((subItem) => {
                          const subNavLink = getNavLink(subItem?.slug);
                          const selected = isSelected(subNavLink);

                          return (
                            <Box
                              key={subItem.name}
                              onClick={() => {
                                hideDrawer();
                                history.push(subNavLink);
                              }}
                              className={classes.navigationLink}
                              style={{
                                backgroundColor: selected
                                  ? theme.colors.menuButtonHoverBackground
                                  : '',
                                color: selected ? '#000000' : theme.colors.smoke,
                                paddingLeft: '24px',
                              }}
                            >
                              {subItem.name}
                            </Box>
                          );
                        })}
                      </List>
                    </Collapse>
                  </Box>
                );
              }

              if (restaurantFetching && !isStaff) {
                return loadingItem(nav?.name);
              }

              const navLink = getNavLink(nav?.slug);
              const selected = isSelected(navLink);

              return (
                <ListItem
                  button
                  key={nav.name}
                  component={NavLink}
                  to={navLink}
                  onClick={hideDrawer}
                  selected={selected}
                  className={classes.navigationLink}
                  style={{
                    color: selected ? '#000000' : theme.colors.smoke,
                    ...(selected
                      ? { backgroundColor: theme.colors.menuButtonHoverBackground }
                      : {}),
                  }}
                >
                  <ListItemText
                    className={classes.navText}
                    disableTypography
                    primary={
                      <Box>
                        <Box className={classes.flex}>
                          {nav.name} {nav?.isNew && <Box className={classes.newLabel}>New</Box>}
                        </Box>
                      </Box>
                    }
                    style={{
                      color: selected ? '#000000' : theme.colors.smoke,
                      ...(selected && { backgroundColor: theme.colors.menuButtonHoverBackground }),
                    }}
                  />
                </ListItem>
              );

              // return (
              //   <Box
              //     key={nav.name}
              //     onClick={() => {
              //       hideDrawer();
              //       history.push(navLink);
              //     }}
              //     className={classes.navigationLink}
              //     style={{
              //       color: selected ? '#000000' : theme.colors.smoke,
              //       ...(selected && { backgroundColor: theme.colors.menuButtonHoverBackground }),
              //     }}
              //   >
              //     <Box className={classes.flex}>
              //       {nav.name} {nav?.isNew && <Box className={classes.newLabel}>New</Box>}
              //     </Box>
              //   </Box>
              // );
            })}
          </List>

          <Divider className={classes.divider} />

          <List className={classes.list}>
            {navigationItems.map((nav) => {
              if (nav.section !== 2) {
                return <React.Fragment key={nav?.name} />;
              }

              if (restaurantFetching && !isStaff) {
                return loadingItem(nav?.name);
              }

              const navLink = getNavLink(nav?.slug);
              const selected = isSelected(navLink);

              return (
                <Box
                  key={nav.name}
                  onClick={() => {
                    hideDrawer();
                    history.push(navLink);
                  }}
                  className={classes.navigationLink}
                  style={{
                    backgroundColor: selected ? theme.colors.menuButtonHoverBackground : '',
                    color: selected ? '#000000' : theme.colors.smoke,
                  }}
                >
                  {nav.name}
                </Box>
                /** <ListItem
                 //   button
                 //   key={nav.name}
                 //   component={Link}
                 //   to={getNavLink(nav?.slug)}
                 //   onClick={hideDrawer}
                 //   selected={isSelected(getNavLink(nav?.slug))}
                 //   style={{
                 //     padding: '0 12px',
                 //     margin: '2px 0',
                 //     ...(selected && { backgroundColor: theme.colors.menuButtonHoverBackground }),
                 //   }}
                 // >
                 //   <ListItemText
                 //     className={classes.navText}
                 //     primary={nav.name}
                 //     style={{
                 //       color: selected ? COLORS.BLACK : COLORS.SMOKE,
                 //     }}
                 //   />
                 // </ListItem> * */
              );
            })}
          </List>
        </Box>
        <Box>
          <Box className={classes.versionNumber}>
            {getVersionText()}, {getBookingsVersion()}
          </Box>

          {allowHideNavigation && (
            <>
              <Divider className={classes.divider} />

              <Box style={{ marginTop: '8px' }}>
                <Box className={classes.navigationLink} onClick={hideDrawer}>
                  <HideNavigationSVG style={{ color: COLORS.BLACK }} />
                  <Box
                    style={{
                      textTransform: 'uppercase',
                      letterSpacing: 1.5,
                      lineHeight: '30px',
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  >
                    Hide navigation
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

NavigationDrawer.propTypes = {
  hideDrawer: PropTypes.func.isRequired,
  allowHideNavigation: PropTypes.bool.isRequired,
  restaurantFetching: PropTypes.bool.isRequired,
  navigationItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: userPropTypes.isRequired,
  restaurantActive: restaurantActivePropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  restaurantFetching: state.restaurants.fetching,
  restaurantActive: state.restaurantActive,
});

export default connect(mapStateToProps, null)(NavigationDrawer);
