import { createTheme } from '@mui/material/styles';
import { isChrome, isOpera } from 'react-device-detect';
import { COLORS, FONT_SIZES, FONT_WEIGHTS } from './EatClubTheme';

// TODO uncomment this and fix all breakpoints. Too risky right now
// const breakpoints = createBreakpoints({
//   values: {
//     xs: 0,
//     sm: 768 + 1,
//     md: 1280 + 1, // Adding 1 to each since 1280 would otherwise be missed by down('md')
//     lg: 1500 + 1,
//     xl: 1920 + 1,
//   },
// });

// TODO delete this one and replace with the above when ready
const breakpointsTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600, // Mobile - not supported
      md: 1025, // Smallest tablet
      lg: 1280, // Normal laptop size
      xl: 1920, // Full width
    },
  },
});

// Only use overlay scrollbars on chrome, they don't work in safari or firefox :(
export const overlay = isChrome || isOpera ? 'overlay' : 'auto';

const colors = {
  white: COLORS.WHITE,
  black: COLORS.BLACK,
  charcoal: COLORS.CHARCOAL,
  charcoalTransparent: COLORS.CHARCOAL_TRANSPARENT,
  cloud: COLORS.CLOUD,
  nimbus: COLORS.NIMBUS,
  smoke: COLORS.SMOKE,
  linenDark: COLORS.LINEN_DARK,
  pageBackground: COLORS.LINEN_LIGHT,
  selectionBackground: '#FCFCFC',
  borderLight: '#EEEEEE',
  hoverLight: '#FCFCFC',
  textWhite: '#D8D8D8', // Used over black backgrounds
  brandTertiary: COLORS.BRAND_YELLOW,

  // Text
  secondaryText: '#A6A6A6',
  headingDark: '#606060',

  // Components
  dropdownBackground: COLORS.LINEN_LIGHT,
  dropdownOutline: COLORS.LINEN_DARK,
  buttonOutlineLight: '#E7E7E7',
  menuButtonHoverBackground: '#ECE6DD',
  dropdownGray: '#f5f5f5',
  dividerLine: COLORS.GRAY_4,

  // Colourful things
  impressions: '#CDD151',
  impressionsLight: '#FCFCCF',
  impressionsDark: '#b2b639',

  customers: '#EF702D',
  customersLight: '#FDF2E6',
  customersDark: '#b3470f',

  aov: '#C4BDE3',
  aovLight: '#F5F4FA',
  aovDark: '#8c7ad8',

  revenue: '#FAD356',
  revenueLight: '#FAF9D8',
  revenueDark: '#c19a1c',

  backgroundBlack: '#211F1B',

  chartBackground: COLORS.PROFIT_YELLOW,
  chartDotDefault: '#ECF2A2',
  chartDotActive: '#EAA655',
  chartDotInvalid: '#A6A6A6',
  chartDivider: COLORS.PROFIT_YELLOW_DARK,
  brownChartDivider: '#E5DDB3',

  revenueTimeChartBackground: '#EEE8CA',
  revenueTimeBarBackground: '#DFD49D',
  // revenueTimeBarBackground: '#8E8167',
  revenueTimeBarFill: '#211F1B',
  revenueTimeBarFillStripe: '#8E8167',

  moonPurple: '#B7ADF3',
  sunOrange: '#FAD14A',
  sunriseRed: '#F18257',

  positiveGreen: '#59A558',
  negativeRed: '#D93636',
  backgroundGreen: '#E1F3E0',
  backgroundRed: '#f3e0e0',
};

const theme = createTheme(breakpointsTheme, {
  overlay,
  colors: {
    ...COLORS,
    ...colors,
  },
  fontWeights: {
    regular: FONT_WEIGHTS.REGULAR,
    medium: FONT_WEIGHTS.MEDIUM,
    bold: FONT_WEIGHTS.BOLD,
    black: FONT_WEIGHTS.BLACK,
  },
  components: {
    scrollbarWide: {
      overflowY: overlay,

      '&::-webkit-scrollbar': {
        paddingLeft: '1px',
        width: '10px',
        borderRadius: '100px',
      },
      '&::-webkit-scrollbar-track-piece': {
        background: COLORS.YET_ANOTHER_GRAY_9,
        borderRadius: '100px',
      },
      '&::-webkit-scrollbar-thumb': {
        '&:hover': {
          background: COLORS.CHARCOAL,
        },

        background: COLORS.YET_ANOTHER_GRAY_10,
        borderRadius: '100px',
      },
      '&::-webkit-scrollbar-button:end:increment': {
        display: 'block',
        background: 'transparent',
      },
    },
    scrollbar: {
      overflowY: overlay,

      '&::-webkit-scrollbar': {
        paddingLeft: '1px',
        width: '6px',
        borderRadius: '100px',
      },
      '&::-webkit-scrollbar-track-piece': {
        background: COLORS.YET_ANOTHER_GRAY_9,
        borderRadius: '100px',
      },
      '&::-webkit-scrollbar-thumb': {
        '&:hover': {
          background: COLORS.CHARCOAL,
        },

        background: COLORS.YET_ANOTHER_GRAY_10,
        borderRadius: '100px',
      },
      '&::-webkit-scrollbar-button:end:increment': {
        display: 'block',
        background: 'transparent',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          textTransform: 'unset',
        },
        containedTumeric: {
          // outline: 'currentColor solid 1px',
        },
        outlined: {
          borderColor: 'rgba(0,0,0,0.2)',
        },
      },
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        disableTouchRipple: true,
        disableFocusRipple: true,
        variant: 'contained',
        color: 'tumeric',
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        disableFocusRipple: true,
        color: 'secondary',
      },
    },
    MuiInput: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '32px',
          boxShadow: '0px 4px 4px 0px #DADADA40',
          padding: '8px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundImage: 'unset' } },
    },
    MuiSkeleton: {
      styleOverrides: { root: { backgroundColor: '#E1D5C3' } },
    },
    MuiTooltip: {
      styleOverrides: {
        root: {
          tooltip: {
            fontSize: '.875em',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          button: {
            '&.Mui-selected': {
              backgroundColor: '#F5F2ED',
            },
            '&:hover': {
              textDecoration: 'none',
              // Reset on touch devices, it doesn't add specificity
              '@media (hover: none)': {
                backgroundColor: 'transparent',
              },
            },
          },
          '&$focusVisible': {
            backgroundColor: colors.menuButtonHoverBackground,
          },
          '&$selected, &$selected:hover': {
            backgroundColor: colors.menuButtonHoverBackground,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#313131',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused fieldset': {
            borderColor: '#313131 !important',
            outline: 'none',
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        transitionDuration: {
          enter: 150,
          exit: 0,
        },
      },
      styleOverrides: {
        paper: {
          borderRadius: '24px',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiPopover: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      },
      styleOverrides: {
        paper: {
          borderRadius: '20px',
          marginTop: '8px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: '8px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          '&:hover': {
            backgroundColor: '#FAF8F5',
          },
          '&.Mui-selected': {
            backgroundColor: '#eeeae3',
            color: '#000',
            '&:hover': {
              backgroundColor: '#eeeae3',
            },
          },
        },
        list: {
          padding: 0,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#FAD14C',
            '&:hover': {
              backgroundColor: '#eac449',
            },
          },
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#ff456a',
      main: '#E54439',
      dark: '#b21030',

      contrastText: '#fff',
    },
    secondary: {
      light: '#333333',
      main: '#000000',
      dark: '#000000',
      contrastText: '#fff',
    },
    tumeric: {
      light: '#fbda6f',
      main: '#FAD14C',
      dark: '#af9235',
      contrastText: '#000',
    },
    error: {
      light: '#ff456a',
      main: '#E54439',
      dark: '#b21030',
      contrastText: '#fff',
    },
    warning: {
      light: '#fab85c',
      main: '#f9a32c',
      dark: '#df8507',
      contrastText: '#fff',
    },
  },
  eatclub: {
    typography: {
      heading: {
        h1: {
          fontSize: FONT_SIZES.H1,
          fontWeight: FONT_WEIGHTS.MEDIUM,
          lineHeight: 'normal',
        },
        h2: {
          fontSize: FONT_SIZES.H2,
          fontWeight: FONT_WEIGHTS.MEDIUM,
          lineHeight: 'normal',
        },
        h3: {
          fontSize: FONT_SIZES.H3,
          fontWeight: FONT_WEIGHTS.MEDIUM,
          lineHeight: 'normal',
        },
        h4: {
          fontSize: FONT_SIZES.H4,
          fontWeight: FONT_WEIGHTS.MEDIUM,
          lineHeight: 'normal',
        },
        h5: {
          fontSize: FONT_SIZES.H5,
          fontWeight: FONT_WEIGHTS.MEDIUM,
          lineHeight: 'normal',
        },
        h6: {
          fontSize: FONT_SIZES.H6,
          fontWeight: FONT_WEIGHTS.MEDIUM,
          lineHeight: 'normal',
        },
      },
      body: {
        xl: {
          fontSize: '22px',
        },
        lg: {
          fontSize: '20px',
        },
        base: {
          fontSize: '16px',
        },
        sm: {
          fontSize: '14px',
        },
        xs: {
          fontSize: '12px',
        },
      },
    },
  },
});

export default theme;
