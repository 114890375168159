import dayjs from 'dayjs';
import * as type from '../actions/types';

const initialState = {
  transactionDetails: [],
  compareTransactionDetails: [],
  pastYearTransactionDetails: [],
  allTransactionDetails: [],
  fetching: true,
  error: false,
  errorMessage: '',
  success: false,
  shouldFetch: true,
};

// Format the transactions to be compatible with the old booking data structure
const convertTransactionsToBookings = (transactions) =>
  transactions.map((transaction) => ({
    ...transaction,
    arrivalTime: transaction.time,
    created: `${transaction.bookingDate}T11:00:00Z`,
    objectId: transaction.bookingId,
    origTotal: `${(transaction.origTotal / 100).toFixed(2)}`,
    revenue: `${(transaction.unsubsidizedTotal / 100).toFixed(2)}`,
    takeaway: transaction.type === 'takeaway' || transaction.type === 'iao',
    total: ((transaction.origTotal + transaction.txFees / 10) / 100).toFixed(2),

    // Missing from transaction
    // platform - TODO this one is important for whitelabel
  }));

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_TRANSACTION_DETAILS_PENDING:
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: '',
      };
    case type.FETCH_TRANSACTION_DETAILS_SUCCESS: {
      const transactionDetails = convertTransactionsToBookings(action.payload?.transactionDetails);
      const compareTransactionDetails = convertTransactionsToBookings(
        action.payload?.compareTransactionDetails,
      );

      return {
        ...state,
        transactionDetails,
        compareTransactionDetails,
        fetching: false,
        success: true,
        shouldFetch: false,
      };
    }
    case type.FETCH_TRANSACTION_DETAILS_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        fetching: false,
      };

    case type.FETCH_PAST_YEAR_TRANSACTION_DETAILS_SUCCESS: {
      const allTransactionDetails = convertTransactionsToBookings(
        action.payload?.pastYearTransactionDetails,
      );

      const pastYearTransactionDetails = allTransactionDetails.filter((booking) =>
        dayjs(booking.bookingDate).isBefore(dayjs().startOf('month')),
      );

      return {
        ...state,
        pastYearTransactionDetails,
        allTransactionDetails,
        fetching: false,
        success: true,
        shouldFetch: false,
      };
    }

    // case type.FETCH_PAST_YEAR_TRANSACTION_DETAILS_SUCCESS: {
    //   const { pastYearTransactionDetails } = action.payload;
    //
    //   return {
    //     ...state,
    //     pastYearTransactionDetails,
    //     fetching: false,
    //     success: true,
    //     shouldFetch: false,
    //   };
    // }
    //
    // case type.FETCH_PAST_YEAR_TRANSACTION_DETAILS_FAILURE:
    //   return {
    //     ...state,
    //     error: true,
    //     errorMessage: action.payload,
    //     fetching: false,
    //   };

    // NOTE: update bookings if restaurant or date changes
    case type.SET_ACTIVE_RESTAURANT:
    case type.SET_SEARCH_DATES:
    case type.SET_SEARCH_DATES_DAYJS:
    case type.SET_DATE_RANGE:
      return {
        ...state,
        shouldFetch: true,
        success: false,
      };

    default:
      return state;
  }
};
