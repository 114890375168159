import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Button, Box, Divider, TextField } from '@eatclub-apps/ec-component-library';
import { Auth } from 'aws-amplify';
import { COLORS } from '../../../EatClubTheme';
import useStyles from './CustomSignInStyles';
import { devLog } from '../../../utils';

const EmailLogin = ({ switchLoginType }) => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [signinLoading, setSigninLoading] = useState(false);
  const [signinError, setSigninError] = useState('');

  const styles = {
    textField: {
      label: {
        fontSize: '14px',
        display: 'none',
      },
      input: {
        borderRadius: '12px',
        fontSize: '15px',
        height: '56px',
      },
    },
    button: {
      button: {
        fontSize: '15px',
        borderRadius: '20px',
        height: '48px',
        backgroundColor: COLORS.BRAND_YELLOW,
        color: COLORS.CHARCOAL_TRANSPARENT,
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setSigninError('You must enter both an email address and password.');
      return;
    }

    setSigninLoading(true);

    try {
      const user = await Auth.signIn(email.trim(), password);
      devLog('success', 'user', user);
    } catch (error) {
      devLog('error', 'user', error);
      setSigninError(error.message);
    } finally {
      setSigninLoading(false);
    }
  };

  return (
    <Box className={classes.signInContent}>
      <h1 className={classes.title}>Log in to EatClub</h1>

      <Box className={classes.contentInner}>
        <Box className={classes.formContainer}>
          <Box className={classes.form}>
            <Box>Enter your email address and password to log in.</Box>

            <TextField
              // autofocus
              autoComplete
              autoCapitalize='none'
              style={styles.textField}
              // disabled={props.user.loginPending ? true : false}
              error={signinError ? ' ' : null}
              placeholder='Email*'
              id='email'
              name='email'
              label='Email'
              variant='outlined'
              fullWidth
              required
              value={email}
              onChange={(newValue) => {
                setSigninError('');
                setEmail(newValue);
              }}
              inputProps={{ inputMode: 'email', autoCapitalize: 'none' }}
              onEnter={handleSubmit}
              autoCompleteType='email'
            />

            <TextField
              autoCapitalize='none'
              autoComplete
              style={styles.textField}
              // disabled={props.user.loginPending ? true : false}
              error={signinError ? ' ' : null}
              placeholder='Password*'
              id='password'
              name='password'
              label='Password'
              variant='outlined'
              type='password'
              fullWidth
              required
              value={password}
              onChange={(newValue) => {
                setSigninError('');
                setPassword(newValue);
              }}
              inputProps={{ inputMode: 'password', autoCapitalize: 'none' }}
              onEnter={handleSubmit}
              toggleVisibility
            />

            {/* <MuiTextField */}
            {/*   autoFocus */}
            {/*   style={styles.textfield} */}
            {/*   // disabled={props.user.loginPending ? true : false} */}
            {/*   // error={formErrors.email ? true : false} */}
            {/*   id='email' */}
            {/*   name='email' */}
            {/*   label='Email' */}
            {/*   variant='outlined' */}
            {/*   fullWidth */}
            {/*   required */}
            {/*   value={email} */}
            {/*   onChange={(e) => setEmail(e.target.value)} */}
            {/*   inputProps={{ inputMode: 'email', autoCapitalize: false }} */}
            {/*   onKeyDown={keyPress} */}
            {/* /> */}
            {/* <MuiTextField */}
            {/*   style={styles.textfield} */}
            {/*   // disabled={props.user.loginPending ? true : false} */}
            {/*   // error={formErrors.password ? true : false} */}
            {/*   id='password' */}
            {/*   name='password' */}
            {/*   label='Password' */}
            {/*   variant='outlined' */}
            {/*   type='password' */}
            {/*   fullWidth */}
            {/*   required */}
            {/*   value={password} */}
            {/*   onChange={(e) => setPassword(e.target.value)} */}
            {/*   onKeyDown={keyPress} */}
            {/* /> */}

            <Button
              type='primary'
              disabled={signinLoading}
              color={COLORS.BRAND_YELLOW}
              style={styles.button}
              fullWidth
              onClick={handleSubmit}
            >
              {signinLoading ? <CircularProgress size={26} color='inherit' /> : 'Log in'}
            </Button>

            <Box style={{ minHeight: '21px' }}>
              {!signinLoading && signinError && (
                <Box style={{ fontSize: '14px', color: 'red' }}>{signinError}</Box>
              )}
            </Box>
          </Box>

          <>
            {window.location.search.includes('fbmarketplace') && (
              <Box
                style={{
                  textAlign: 'center',
                  fontSize: '15px',
                  color: COLORS.SMOKE,
                  marginTop: '16px',
                }}
              >
                Integrate your EatClub account with Facebook
              </Box>
            )}
          </>

          <Box className={classes.bottom}>
            <Divider />

            <span className={classes.helperText}>
              Revert back to{' '}
              <Button
                type='text'
                style={{
                  container: {
                    display: 'inline-block',
                  },
                  button: {
                    textDecoration: 'underline',
                    color: 'black',
                    display: 'inline-block',
                  },
                }}
                onClick={() => {
                  switchLoginType();
                }}
              >
                password-less login
              </Button>
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailLogin;
