import * as type from '../actions/types';
import { safeParse } from '../utils/helpers';

const initialState = {
  data: [],
  pending: false,
  error: '',
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_PREDICTIONS_FOR_OFFER_PENDING: {
      return {
        ...initialState,
        pending: true,
      };
    }
    case type.FETCH_PREDICTIONS_FOR_OFFER_SUCCESS: {
      return {
        ...state,
        data: safeParse(action.payload.data),
        pending: false,
        success: true,
      };
    }
    case type.FETCH_PREDICTIONS_FOR_OFFER_FAILURE: {
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    }

    // Allows skipping the loading state after a few seconds
    case type.SET_PREDICTIONS_FOR_OFFER_LOADING_OVERRIDE: {
      return {
        ...state,
        pending: !action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
