import { Divider } from '@mui/material';
import React from 'react';
import { Button, Box } from '@eatclub-apps/ec-component-library';
import useStyles from './MagicLinkSuccessMobileStyles';
import ServedSVG from '../../../assets/icons/served.svg';

const MagicLinkSuccessMobile = ({
  email,
  resendEmail,
  resending = false,
  message,
  error,
  resetForm,
}) => {
  const classes = useStyles();

  const buttonStyles = {
    button: {
      fontSize: '15px',
      fontWeight: 'normal',
      padding: '8px 32px',
      backgroundColor: 'transparent',
      height: '44px',
      overflow: 'hidden',
    },
  };

  return (
    <Box className={classes.magicLinkSuccessMobile}>
      <h1 className={classes.title}>Your login link has been served</h1>
      <Box>
        A login link has been emailed to {email || 'your email address'}. Click the link to sign in
        to EatClub.
      </Box>

      <Box className={classes.imageContainer}>
        <ServedSVG />
      </Box>

      <Box className={classes.resend}>
        <Box>Didn’t receive the email?</Box>
        <Button type='secondary' style={buttonStyles} loading={resending} onClick={resendEmail}>
          Resend email
        </Button>
      </Box>
      {message && <Box>{message}</Box>}
      {error && <Box style={{ color: 'red' }}>{error}</Box>}

      <Box className={classes.footer}>
        <Divider />
        <span className={classes.helperText}>
          Go back to{' '}
          <Button
            type='text'
            style={{
              container: {
                display: 'inline-block',
              },
              button: {
                textDecoration: 'underline',
                color: 'black',
                display: 'inline-block',
              },
            }}
            onClick={() => {
              resetForm();
            }}
          >
            login home
          </Button>
        </span>
      </Box>
    </Box>
  );
};

export default MagicLinkSuccessMobile;
