import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { CircularProgress, useMediaQuery } from '@mui/material';
import { Button, Box, Divider, TextField } from '@eatclub-apps/ec-component-library';
import { publicApiCall } from '../../../actions/actions';
import { COLORS } from '../../../EatClubTheme';
import { ssoMagicLink } from '../../../graphql/queries';
import useStyles from './CustomSignInStyles';
import { isEmpty } from '../../../utils/helpers';
import MagicLinkSuccess from './MagicLinkSuccess';
import MagicLinkSuccessMobile from './MagicLinkSuccessMobile';

const MagicLinkLogin = ({ switchLoginType }) => {
  const classes = useStyles();

  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const [email, setEmail] = useState('');

  const [hasSent, setHasSent] = useState(false);
  const [magicLinkMessage, setMagicLinkMessage] = useState('');
  const [magicLinkError, setMagicLinkError] = useState('');
  const [magicLinkLoading, setMagicLinkLoading] = useState(false);

  const styles = {
    textField: {
      label: {
        fontSize: '14px',
        display: 'none',
      },
      input: {
        borderRadius: '12px',
        fontSize: '15px',
        height: '56px',
      },
    },
    button: {
      button: {
        fontSize: '15px',
        borderRadius: '20px',
        height: '48px',
        backgroundColor: COLORS.BRAND_YELLOW,
        color: COLORS.CHARCOAL_TRANSPARENT,
      },
    },
  };

  const handleEmailLogin = async () => {
    if (isEmpty(email)) {
      setMagicLinkError('Please enter a valid email address');
      return;
    }

    if (!isEmpty(email)) {
      setMagicLinkLoading(true);
      setMagicLinkError('');

      const response = await publicApiCall(ssoMagicLink, { email });

      setMagicLinkLoading(false);

      // Handle errors (e.g. invalid email)
      if (!isEmpty(response?.error)) {
        setMagicLinkMessage('');
        setMagicLinkError(response?.error?.message);
        return;
      }

      setHasSent(true);
    }
  };

  const resendEmailLogin = async () => {
    setMagicLinkMessage('');
    await handleEmailLogin();
    setMagicLinkMessage(`A login link has been resent to ${email}`);
  };

  const resetForm = () => {
    setHasSent(false);
    setMagicLinkMessage('');
    setMagicLinkError('');

    // Also scroll back to top
    window.scrollTo(0, 0);
  };

  return (
    <Box className={classes.signInContentOuter}>
      <Box
        className={classes.signInContent}
        style={{
          opacity: hasSent ? 0 : 1,
          pointerEvents: hasSent ? 'none' : 'auto',
          transition: 'opacity 0.2s', // Add a cool fade to the success screen
          position: 'absolute',
          top: 0,
        }}
      >
        <h1 className={classes.title}>Log in to EatClub</h1>

        <Box className={classes.contentInner}>
          <Box className={classes.formContainer}>
            <Box className={classes.form}>
              <Box>Enter your email to receive a magic link to access your account.</Box>

              <TextField
                // autofocus
                autoCapitalize='none'
                autoComplete
                style={styles.textField}
                // disabled={props.user.loginPending ? true : false}
                // error={formErrors.email ? true : false}
                placeholder='Email*'
                id='email'
                name='email'
                label='Email'
                variant='outlined'
                fullWidth
                required
                value={email}
                // onChange={(e) => setEmail(e.target.value)}
                onChange={(newValue) => setEmail(newValue)}
                inputProps={{ inputMode: 'email', autoCapitalize: 'none' }}
                onEnter={handleEmailLogin}
              />

              <Button
                type='primary'
                // Note - keep it spinning and disabled on success so it doesn't look janky when transitioning
                disabled={magicLinkLoading || hasSent}
                color={COLORS.BRAND_YELLOW}
                style={styles.button}
                fullWidth
                onClick={handleEmailLogin}
              >
                {magicLinkLoading || hasSent ? (
                  <CircularProgress size={26} color='inherit' />
                ) : (
                  'Log in with email'
                )}
              </Button>

              <Box style={{ minHeight: '21px' }}>
                {!magicLinkLoading && magicLinkError && (
                  <Box style={{ fontSize: '14px', color: 'red' }}>{magicLinkError}</Box>
                )}
              </Box>
            </Box>

            <Box className={classes.bottom}>
              <Divider />

              <span className={classes.helperText}>
                Or, alternatively{' '}
                <Button
                  type='text'
                  style={{
                    container: {
                      display: 'inline-block',
                    },
                    button: {
                      textDecoration: 'underline',
                      color: 'black',
                      display: 'inline-block',
                    },
                  }}
                  onClick={() => {
                    switchLoginType();
                  }}
                >
                  Log in with password
                </Button>
              </span>
            </Box>
          </Box>
        </Box>
      </Box>

      {hasSent && (
        <Box>
          {breakpointSmDown ? (
            <MagicLinkSuccessMobile
              email={email}
              resending={magicLinkLoading}
              resendEmail={resendEmailLogin}
              message={magicLinkMessage}
              error={magicLinkError}
              resetForm={resetForm}
            />
          ) : (
            <MagicLinkSuccess
              email={email}
              resending={magicLinkLoading}
              resendEmail={resendEmailLogin}
              message={magicLinkMessage}
              error={magicLinkError}
              resetForm={resetForm}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default MagicLinkLogin;
