import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../EatClubTheme';

import { drawerWidth } from '../../../constants';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      backgroundColor: '#FAF8F5',
      minHeight: '100vh',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        padding: `72px`,
        // padding: `0 72px 1px 72px`, // That 1px prevents the scrollbar from breaking
        justifyContent: 'center',
      },
      [theme.breakpoints.down('md')]: {
        padding: `20px`,
      },
    },
    containerInner: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '1424px',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    appBar: {
      color: '#2E2E2E',
      boxShadow: 'none',
      marginBottom: '24px',
    },
    menuLogo: {
      left: '0',
      margin: '0 auto',
      position: 'absolute',
      right: '0',
      width: '150px',
      color: '#E54439',
    },
    sidebar: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: '#F5F2ED',
      height: '100%',
    },
    list: {
      marginTop: '16px',
      fontSize: '22px',
    },
    header: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        marginLeft: '0',
      },
    },
    mobileHeader: {
      alignItems: 'center',
      background: COLORS.LINEN,
      display: 'flex',
      padding: '20px',
      position: 'sticky',
      zIndex: 99,
      top: 0,
      height: '66px',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    breadcrumbContainer: {
      justifyContent: 'center',
      width: '100%',
    },
    headerToolbar: {
      ...theme?.mixins?.toolbar,
      paddingLeft: '0',
      paddingRight: '0',
      marginBottom: '20px',
      // justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        // paddingLeft: '40px',
      },
    },
    toolbar: {
      ...theme?.mixins?.toolbar,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      // padding: theme.spacing(0, 0),
      // padding: '0 40px',
      backgroundColor: '#F5F2ED',
    },
    drawerPaper: {
      width: drawerWidth,
      overflowX: 'hidden',
    },
    title: {
      flexGrow: 1,
      fontSize: '40px',
      fontWeight: '500',
      // paddingBottom: '10px',
      maxWidth: '500px',
      lineHeight: '1.36',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      height: '100%',
      gap: '20px',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
    },
    headerBackNavigation: {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
    },
    backNavigationLabel: {
      fontSize: '18px',
    },
    backArrowContainer: {
      width: '19px',
      height: '19px',
      position: 'relative',
    },
    hide: {
      display: 'none',
    },
    logo: {
      height: '35px',
      width: '300px',
      display: 'block',
    },
    navText: {
      '& > span': {
        fontSize: '22px',
      },
    },
    headerDivider: {
      margin: '10px 40px 0px 40px',
      backgroundColor: '#E4E4E4',
      [theme.breakpoints.down('md')]: {
        margin: '10px 20px 0px 20px',
      },
    },
    headerButton: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: '100%',
      },
    },
    flex: {
      display: 'flex',
      gap: '16px',
      alignItems: 'center',
    },
    newLabel: {
      color: COLORS.WHITE,
      backgroundColor: COLORS.OLIVE_2,
      padding: '4px 16px',
      borderRadius: '6px',
      letterSpacing: 1.5,
    },
  }),
  { name: 'Header' },
);

export default useStyles;
