import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../EatClubTheme';

const useStyles = makeStyles(
  (theme) => ({
    magicLinkSuccessContent: {
      fontSize: '15px',
      borderRadius: '36px',
      border: `1px solid ${COLORS.CLOUD}`,
      maxWidth: '924px',
      minHeight: '400px',
      margin: '0 auto 0',
      width: '100%',
      padding: '24px',
      gap: '20px',
      display: 'flex',
      backgroundColor: 'white',
      justifyContent: 'space-between',
      // flexWrap: 'wrap',
    },
    title: {
      fontSize: '44px',
      fontWeight: '500',
      lineHeight: '1.2',
      margin: 0,
      padding: 0,
      marginTop: '-12px', // Bringing it up a tad makes it look better
    },

    left: {
      display: 'flex',
      flexDirection: 'column',
      gap: '40px',
      width: '100%',
      maxWidth: '400px',
      justifyContent: 'space-between',
      padding: '16px 0 6px 16px',
    },

    leftTop: {
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
      justifyContent: 'space-between',
    },
    leftBottom: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    imageContainer: {
      width: '310px',
      height: '400px',
      backgroundColor: '#F0EBCC',
      borderRadius: '24px',
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',

      [theme.breakpoints.down('md')]: {
        // maxWidth: '250px',
        flexShrink: 1,

        '& > *': {
          maxWidth: '100%',
        },
      },
    },

    helperText: {
      color: theme.colors.smoke,
      fontSize: '15px',
      // display: 'flex',
      gap: '4px',
    },
  }),
  { name: 'MagicLinkSuccess' },
);

export default useStyles;
