import * as type from '../actions/types';
import { safeParse } from '../utils/helpers';

const initialState = {
  data: null,
  error: '',
  fetching: false,
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_AFFECTED_BOOKINGS_FOR_DEAL_EDITS_PENDING:
      return {
        ...state,
        fetching: true,
        error: '',
      };
    case type.FETCH_AFFECTED_BOOKINGS_FOR_DEAL_EDITS_SUCCESS:
      return {
        ...state,
        data: safeParse(action.payload.data)?.numRedemptions ?? 0,
        fetching: false,
        success: true,
      };
    case type.FETCH_AFFECTED_BOOKINGS_FOR_DEAL_EDITS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case type.CLEAR_AFFECTED_BOOKINGS_FOR_DEAL_EDITS:
      return {
        ...state,
        data: null,
        fetching: false,
        error: '',
        success: false,
      };

    default:
      return state;
  }
};
