import { CircularProgress } from '@mui/material';
import React from 'react';
import { Button, Box, Spacer } from '@eatclub-apps/ec-component-library';
import useStyles from './MagicLinkSuccessStyles';
import ServedSVG from '../../../assets/icons/served.svg';

const MagicLinkSuccess = ({ email, resendEmail, resending, message, error, resetForm }) => {
  const classes = useStyles();

  const buttonStyles = {
    button: {
      fontSize: '15px',
      fontWeight: 'normal',
      padding: '8px 32px',
      backgroundColor: 'transparent',
      height: '44px',
      overflow: 'hidden',
      minWidth: '166px',
    },
  };

  return (
    <Box className={classes.magicLinkSuccessContent}>
      <Box className={classes.left}>
        <Box className={classes.leftTop}>
          <h1 className={classes.title}>Your login link has been served</h1>
          <Box>
            A login link has been emailed to {email || 'your email address'}. Click the link to sign
            in to EatClub.
          </Box>
        </Box>
        <Box className={classes.leftBottom}>
          <Box>Didn’t receive the email?</Box>
          <Spacer direction='vertical'>
            <Button type='secondary' style={buttonStyles} loading={resending} onClick={resendEmail}>
              {resending ? <CircularProgress /> : 'Resend email'}
            </Button>
            {message && <Box>{message}</Box>}
            {error && <Box style={{ color: 'red' }}>{error}</Box>}
          </Spacer>
        </Box>

        <Box>
          <span className={classes.helperText}>
            Go back to{' '}
            <Button
              type='text'
              style={{
                container: {
                  display: 'inline-block',
                },
                button: {
                  textDecoration: 'underline',
                  color: 'black',
                  display: 'inline-block',
                },
              }}
              onClick={() => {
                resetForm();
              }}
            >
              login home
            </Button>
          </span>
        </Box>
      </Box>

      <Box className={classes.imageContainer}>
        <ServedSVG />
      </Box>
    </Box>
  );
};

export default MagicLinkSuccess;
