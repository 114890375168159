// Experimenting with having a more centralised set of styles with individually
// exported properties

export const COLORS = {
  WHITE: '#FFFFFF',
  LIGHT_CLOUD: '#EEEEEE',
  BLACK: '#000000',
  BRAND_PRIMARY: '#E54439',
  PRIMARY_RED_MATTE: '#E7554B',
  BRAND_YELLOW: '#FAD14C',
  GRAPEFRUIT: '#F8E4E0',
  CONFIRMED_GREEN: '#E9F1D6',
  CONFIRMED_GREEN_TEXT: '#2F4430',
  SMOKE: '#6E6E6D',
  DARKER_SUNNY_GREEN: '#619260',
  GREEN_LABEL: '#7CA87B',
  OLIVE: '#28524B',
  OLIVE_TEXT: '#42756C',
  OLIVE_2: '#386D64',
  OLIVE_3: '#5A9488',
  OLIVE_4: '#86AEA6',
  OLIVE_5: '#E1EAE8',
  LINEN: '#F5F2ED',
  TARGET: '#F3E6CD',
  TARGET_BLUE: '#A5BEB9',
  NOTIFICATION_BACKGROUND_YELLOW: '#FAF5EC',
  DUNE: '#F2EFEE',
  WALLABY: '#F2EFEE', // This was renamed?
  OFFER_CARD_COMPLETED_BACKGROUND: '#F9FBF9',
  OFFER_CARD_STATUS_BAR_COMPLETED: '#6EA76D',
  OFFER_CARD_STATUS_BAR_ACTIVE: '#F6DBD5',
  ROQUETTE: '#5D995C',
  DISABLED: '#F34C41',
  NOTIFICATION_GOOD_BACKGROUND: '#F9FBF9',
  NOTIFICATION_GOOD_OUTLINE: '#6EA76D',
  GOAL_POST_BANNER_HEADER: '#E8594F',
  LIST_ITEM_SELECTED: '#EEF2F1',
  POPOVER_BACKGROUND: '#00000070', // hex colours also allow transparency
  PILL_AVAILABLE: '#86B186',
  AVAILABLE_BACKGROUND: '#EBF2EB',
  UNAVAILABLE_BACKGROUND: '#FAE1DF',
  ORANGE: '#EBC39E',
  BANNER_BACKGROUND: '#F1F5ED', // Note: was F5F2ED in develop
  BURNT_ORANGE: '#E07D5C',
  TURMERIC: '#EAD16A',
  ROSE: '#EEC1BB',
  CAKE_BACKGROUND: '#F2CFCA',
  TURMERIC_LIGHT: '#FBF8EA',
  ROQUETTE_LIGHT: '#ECF4EC',
  NO_SHOW_OUTLINE: '#E08A7E',
  GREEN_SHADE_HIGHLIGHT: '#E6EDEC',
  ANOTHER_ORANGE: '#E8A263',
  BREAKFAST_TIME: '#F7EAB6',
  PEACH: '#F9E7D6',
  GREEN_BACKGROUND: '#E3ECE3',
  INFORMATION_BLUE: '#0E3AB9',
  WARNING_ORANGE: '#DE8E50',
  ERROR_RED: '#DA5A5A',
  BANNER_BLUE: '#E5ECF5',
  LINEN_DARK: '#DFD7C8',
  LINEN_BUTTON: '#EAE4DB',
  LINEN_BUTTON_OUTLINE: '#E7E1D7',
  LINEN_LIGHT: '#FAF8F5',

  // shades of gray :(
  BODY_TEXT: '#313131',
  CHARCOAL: '#313131',
  CHARCOAL_TRANSPARENT: '#000000D0', // Black with transparency that makes it #313131 over white backgrounds
  CLOUD: '#F0F0F0',
  NIMBUS: '#AEAEAE',
  DISABLED_GOAL_CARD: '#F9F9F9',
  NOTIFICATION_BACKGROUND_GRAY: '#F4F4F4',
  FAKE_TEXT_FIELD: '#EDEDED',
  LINK_ICON_BACKGROUND: '#ECECEC',
  SHARK: '#DCDCDB',
  DATE_SELECT_BACKGROUND: '#F5F5F5',
  DISABLED_TEXT: '#B1B1B1',
  STAFF_BLUE: '#C4D8F3',
  TEXT_GRAY: '#A1A1A1',
  GRAY_BORDER: '#DCDCDB',
  GRAY_LIGHT: '#7E7E7E',
  LIGHT_SHADE: '#F9F9F9',
  CARD_OUTLINE: '#E9E9E9',
  BORDER_GRAY: '#E8E8E8',
  JET: '#1A1919',
  GRAY_3: '#969696',
  GRAY_4: '#C4C4C4',
  GRAY_5: '#D5D5D5',
  GRAY_6: '#F7F6F5',
  GRAY_7: '#E7E7E7',
  GRAY_8: '#DCDCDC',
  YET_ANOTHER_GRAY: '#F1F1F1',
  YET_ANOTHER_GRAY_2: '#EEEEEE',
  YET_ANOTHER_GRAY_3: '#E0E0E0',
  YET_ANOTHER_GRAY_4: '#F8F8F8',
  YET_ANOTHER_GRAY_5: '#828282',
  YET_ANOTHER_GRAY_6: '#979797',
  YET_ANOTHER_GRAY_7: '#8F8F8F',
  YET_ANOTHER_GRAY_8: '#D2D2D2',
  YET_ANOTHER_GRAY_9: '#F2F2F2',
  YET_ANOTHER_GRAY_10: '#575757',
  YET_ANOTHER_GRAY_11: '#E4E4E4',
  YET_ANOTHER_GRAY_12: '#D3CCC9',
  YET_ANOTHER_GRAY_13: '#F9F8F8',
  YET_ANOTHER_GRAY_14: '#B3B3B3',

  MANDARIN: '#E7B78B',
  RED: '#EB5757',
  TUMERIC: '#EAD16A',
  TUMERIC_DARKER: '#E4C43F',
  CANCELLED_PINK: '#EBCCCC',
  FINISHED_GREEN: '#1F4C46',
  FINISHED_GREEN_DARKER: '#173531',
  ALABASTER: '#EFEBE4',
  WARNING_ICON: '#E09A63',
  APRICOT: '#DE9863',
  APRICOT_LIGHT: '#F7E6D9', // NOTE: Should these light/dark colors be calcuated programmically if its just an aplha shift?
  BLUE: '#96B1D5',
  SELECTED_LIST_ITEM: '#F0EBE4',
  SUCCESS_GREEN_LIGHT: '#E3ECE2',
  ERROR_RED_LIGHT: '#F9E6E6',
  INFO_BLUE: '#2A476A',
  INFO_BLUE_LIGHT: '#E5ECF5',

  PROFIT_YELLOW: '#F8D04B', // formerly #EDCC3E
  PROFIT_YELLOW_DARK: '#E8C119',
};

export const BORDER_RADII = {
  S: '4px',
  M: '8px',
};

export const FONT_SIZES = {
  H1: '40px',
  H2: '32px',
  H3: '22px',
  H4: '18px',
  H5: '16px',
  H6: '12px',
};

export const LINE_HEIGHTS = {
  XS: '10px',
  S: '14px',
  M: '16px',
  L: '20px',
  L2: '22px', // Since there's no consistency yet
  L3: '24px', // Since there's no consistency yet
  XL: '28px',
  XXL: '32px',
  '3XL': '34px',
  '4XL': '40px',
};

export const FONT_WEIGHTS = {
  REGULAR: 400,
  MEDIUM: 500,
  BOLD: 700,
  BLACK: 900,
};

export const SPACING = {
  PXL: '22px',
  PL: '18px',
  P: '16px',
  PS: '14px',
  // Everything below is deprecated

  XXS: '6px',
  XS: '8px',
  S: '10px',
  M: '16px',
  L: '20px',
  XL: '24px',
  XXL: '28px',
};

export const SCROLLBAR = {};

const theme = {};

export default theme;
