export * from './discounts';
export * from './formats';
export * from './platform';

/** Width of the side drawer */
export const drawerWidth = 313;

export const CONTACT_ACC_MANAGER_SUBJECT = {
  OPENING_HOURS: 'Update Opening Hours',
  UPDATE_PROFILE: 'Update Menus or Photos',
  UPDATE_DEALS: 'Update Deals',
  OTHER: '(Other)',
};
