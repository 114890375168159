import { API } from 'aws-amplify';
import store from '../store';
import { devLog } from '../utils';
import { generateRandomString, getAppVersion, isEmpty, safeParse } from '../utils/helpers';
import * as type from './types';

export const addMessage = (action, dispatch, context = 'success', message) => {
  const errorType = context.toUpperCase();

  if (message) {
    dispatch({
      type: type.ADD_MESSAGE,
      payload: {
        id: `${action}_${errorType}_${new Date().getTime()}`,
        message,
        severity: context,
      },
    });
  }
};

/**
 * Takes a generic graphql call and handles the responses
 *
 * @returns {Promise<{response: null, error: null, status: null}>}
 */
export const genericApiCall = async (options, additionalHeaders) => {
  const response = {
    response: null,
    status: null,
    error: null,
  };

  try {
    const apiResponse = await API.graphql(options, additionalHeaders);

    response.response = apiResponse;
    response.status = 'success';

    if (!isEmpty(apiResponse?.errors)) {
      devLog('info', 'Response had errors', apiResponse);

      const errorMessage =
        apiResponse?.errors?.[0]?.message || apiResponse?.error?.message || apiResponse;
      const errorData = safeParse(errorMessage, errorMessage);

      response.status = 'error';
      response.response = apiResponse;
      response.error = {
        message: `${errorData?.message || errorData}`,
        data: errorData || {},
      };
    }
  } catch (e) {
    devLog('info', 'there was an error', e);
    // Message will typically be in a structure as the first of a list of errors, with data embedded in the message
    // The message property is either a string (e.g. "An error occurred") or an object with data
    const errorMessage = e?.errors?.[0]?.message || e?.error?.message || e;
    const errorData = safeParse(errorMessage, errorMessage);
    response.status = 'error';
    response.response = e;
    response.error = {
      message: `${errorData?.message || errorData}`,
      data: e?.errors || {},
    };
  }

  devLog(response.status, 'API response', response);

  return response;
};

/**
 * Makes an API call with error handling.
 * Returns an object with the API response, status, and error if there was one
 *
 * @param query
 * @param variables
 * @param addRestId
 * @returns {Promise<{response: null, error: null, status: null}>}
 */
export const apiCall = (query, variables, addRestId = false) => {
  // Get state here so it doesn't have to be passed in from every component
  const state = store?.getState();
  const restId = state.restaurantActive.restaurant.objectId;
  const userId = state?.user?.userInfo?.objectId;
  const { userType } = state?.user?.userInfo;

  const variablesWithExtra = {
    ...variables,
    ...(addRestId ? { restId } : {}),
  };

  const options = {
    query,
    variables: variablesWithExtra,
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  };

  const additionalHeaders = {
    'user-id': userId,
    'user-type': userType,
    'rest-id': restId,
    'app-version': getAppVersion(),
  };

  return genericApiCall(options, additionalHeaders);
};

/**
 * Makes an API call without being logged in
 *
 * @param query
 * @param variables
 * @returns {Promise<{response: null, error: null, status: null}>}
 */
export const publicApiCall = async (query, variables) => {
  const variablesWithExtra = {
    ...variables,
  };

  const queryOptions = {
    query,
    variables: variablesWithExtra,
    authMode: 'API_KEY',
  };

  const additionalHeaders = {
    'app-version': getAppVersion(),
  };

  return genericApiCall(queryOptions, additionalHeaders);
};

export const paginatedRequest = (region, query, variables, requestName, pageSize = 500) =>
  new Promise((resolve, reject) => {
    const paginatedItems = [];

    const requestLoop = async (offset) => {
      try {
        const variablesWithPagination = {
          ...variables,
          pageSize,
          offset,
        };

        const response = await apiCall(query, variablesWithPagination, true);

        if (response.error) {
          throw new Error(response.error);
        }

        // destructure
        const items = response?.response?.data?.[requestName];

        if (!items) {
          throw new Error(`${requestName} null`);
        }

        // congregate data
        if (items.length > 0) {
          paginatedItems.push(...items);
        }

        // But wait, there's more!
        if (items.length >= pageSize) {
          requestLoop(pageSize, offset + pageSize);
          return;
        }

        devLog('success', requestName, paginatedItems);

        // Success resolve
        resolve(paginatedItems);
      } catch (error) {
        devLog('error', requestName, error);
        reject(error);
      }
    };

    requestLoop(0);
  });

export const beginAction = (action, dispatch, object, tempId, requestId) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `${action}_${requestId}`,
  });

  dispatch({
    type: `${action}_PENDING`,
    payload: {
      data: object,
      tempId,
    },
  });
};

export const endAction = (action, dispatch, requestId) => {
  dispatch({
    type: type.REMOVE_ID_APP_LOADING,
    payload: `${action}_${requestId}`,
  });
};

export const handleFailure = (error, action, model, dispatch, customErrorMessage) => {
  let messageToShow = `Unable to complete action for ${model}`;

  if (error) {
    messageToShow += `: ${error?.message}`;
  }

  if (!isEmpty(customErrorMessage)) {
    if (typeof customErrorMessage === 'function') {
      messageToShow = customErrorMessage(`${error?.message}`);
    } else {
      messageToShow = customErrorMessage;
    }
  }

  devLog('error', model, messageToShow, error);
  addMessage(action, dispatch, 'error', messageToShow);

  dispatch({
    type: `${action}_FAILURE`,
    // payload: { error: messageToShow, errorData: error?.data, response }, // TODO use detailed payload eventually
    payload: messageToShow,
  });
};

/**
 *
 * @param {string} id unique identifier of the request
 * @param {int} timestamp when the request was made in ms
 * @param {string} model model (query) identifier of the request
 * @param {Promise} request promise instance of the api call
 * @param {*} dispatch
 */
const addRequest = (id, timestamp, model, request, dispatch) => {
  dispatch({
    type: type.ADD_API_REQUEST,
    payload: { id, timestamp, model, request },
  });
};

/**
 *
 * @param {string} model model (query) identifier of the request
 * @param {int} now when the request was made in ms
 */
const cancelRequests = (model, now) => {
  const state = store?.getState();
  const { apiRequests } = state;

  apiRequests.forEach((apiRequest) => {
    if (apiRequest.model === model && now > apiRequest.timestamp) {
      devLog('info', 'cancelling api request', apiRequest);
      API.cancel(apiRequest.request);
    }
  });
};

/**
 *
 * @param {string} id unique identifier of the request
 * @param {*} dispatch
 */
const removeRequest = (id, dispatch) => {
  dispatch({
    type: type.REMOVE_API_REQUEST,
    payload: id,
  });
};

export const handleResponse = (
  response,
  action,
  model,
  dispatch,
  tempId,
  variables = {},
  extraValues = {},
  onCompletion = null,
  successMessage = null,
  errorMessage = null,
  customValidation = null,
) => {
  if (customValidation) {
    const customValidationResponse = customValidation(response);

    // If not true, then it's an error message
    if (customValidationResponse !== true) {
      handleFailure(response?.error, action, model, dispatch, errorMessage, response);
      return;
    }
  }

  // Check for error message in response
  const error = response?.error ?? response?.status === 'error';
  if (error) {
    handleFailure(error, action, model, dispatch, errorMessage, response);
    return;
  }

  // Responses are typically held by their resource name
  let data = response?.response?.data;
  if (model) {
    data = response?.response?.data?.[model];
  }

  if (import.meta.env.VITE_LOG_API_SUCCESSES === 'true') {
    devLog('success', model, data);
  }

  // Show success state
  dispatch({
    type: `${action}_SUCCESS`,
    payload: {
      data,
      variables,
      tempId,
      ...extraValues,
    },
  });

  if (successMessage) {
    addMessage(action, dispatch, 'success', successMessage);
  }

  if (onCompletion) {
    onCompletion();
  }
};

/**
 * Makes an api call with redux state handling
 *
 * TODO support pagination
 *
 * @param action
 * @param query
 * @param variables
 * @param pendingObject
 * @param model
 * @param includeRestId
 * @param dispatchOLD
 * @param successMessage
 * @param extraValues
 * @param skipLoading
 * @param errorMessage
 * @param cancelStaleApiRequests
 * @param onCompletion
 * @param customValidation
 * @returns {(function(*): Promise<void>)|*}
 */
export const makeApiAction =
  (
    action,
    query,
    variables,
    pendingObject, // The object being stored (e.g. so we can add it to the state)
    model,
    includeRestId, // Whether to inject the restId into the variables data
    dispatchOLD = null, // @deprecated TODO remove this
    successMessage = null, // If provided, show a log message on success
    extraValues = {}, // Extra values we might want to return to the reducer
    skipLoading = false, // Whether to prevent setting the app into a loading state. e.g. when polling
    errorMessage = null, // If provided, show a log message on error
    cancelStaleApiRequests = false, // Whether to cancel stale api requests of the same `model`
    onCompletion = null, // Function to run when request returns successfully
    customValidation = () => true, // Custom handling of success/error states. Return true for valid or a string for invalid
  ) =>
  async (dispatch) => {
    const tempId = generateRandomString(8);
    const requestId = generateRandomString(16);

    if (!skipLoading) {
      beginAction(action, dispatch, pendingObject, tempId, requestId);
    }

    // Set up the promise for the request
    const request = apiCall(query, variables || {}, includeRestId);

    // Make the request cancellable
    if (cancelStaleApiRequests) {
      const now = new Date().getTime();
      cancelRequests(model, now);
      addRequest(requestId, now, model, request, dispatch); // note: store the request meta data in reducer for stale api cancelling
    }

    try {
      if (query) {
        const response = await request;

        handleResponse(
          response,
          action,
          model,
          dispatch,
          tempId,
          variables || {},
          extraValues,
          onCompletion,
          successMessage,
          errorMessage,
          customValidation,
        );
      }
    } catch (error) {
      if (API.isCancel(error)) {
        devLog('info', 'api request cancelled', { id: requestId, model });
      } else {
        handleFailure(error?.errors?.[0]?.message || error, action, model, dispatch, tempId);
      }
    } finally {
      if (cancelStaleApiRequests) {
        removeRequest(requestId, dispatch);
      }

      if (!skipLoading) {
        endAction(action, dispatch, requestId);
      }
    }
  };
