import { useTheme } from '@mui/material/styles';
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateRange as ECDateRange } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import dayjs from 'dayjs';
import {
  setDateRangeAction,
  setDatesAction,
  setDatesDayjsAction,
} from '../../actions/datePickerAction';
import { devLog, useQuery } from '../../utils';
import { isEmpty } from '../../utils/helpers';
import { dateRangeOptions } from '../../utils/insights/insightHelpers';

const DatePicker = ({
  dates = {},
  setDatesDayjs = () => {},
  setDateRange = () => {},
  setDates = () => {},
}) => {
  const theme = useTheme();
  const query = useQuery();

  const startDate = query.get('startDate');
  const endDate = query.get('endDate');

  // Automatically set the date if it's set in the URL
  useEffect(() => {
    if (!isEmpty(startDate) || !isEmpty(endDate)) {
      const newStartDate = startDate ?? dates?.startDate;
      const newEndDate = endDate ?? dates?.endDate;

      setDates(dayjs(newStartDate), dayjs(newEndDate));
    }
  }, [startDate, endDate]);

  // Get the dates to show in the calendar
  const dayJsDates = useMemo(() => {
    // If date range is all time, don't make the calendar go all the way back like that
    if (dates.value !== null) {
      return {
        ...dates,
        startDate: dates?.endDate.subtract(1, 'month'),
        endDate: dates?.endDate,
      };
    }

    return {
      ...dates,
      startDate: dates?.startDate,
      endDate: dates?.endDate,
    };
  }, [dates.value, dates?.startDate, dates?.endDate]);

  const handleDayUpdate = (newFilters) => {
    const wasChanged =
      !dates?.startDate.isSame(newFilters?.startDate, 'day') ||
      !dates?.endDate.isSame(newFilters?.endDate, 'day');

    if (wasChanged) {
      devLog('info', 'updating dates', newFilters);
      setDatesDayjs(newFilters?.startDate, newFilters?.endDate, newFilters?.dateRange);
    }
  };

  if (isEmpty(dayJsDates)) {
    return <></>;
  }

  return (
    <>
      <ECDateRange
        filters={dayJsDates}
        setFilters={handleDayUpdate}
        dateRangeOptions={dateRangeOptions}
        setDateRange={setDateRange}
        style={{
          dropdown: {
            dropdown: {
              backgroundColor: theme.colors.pageBackground,
            },
            menu: {
              maxHeight: '700px',
            },
          },
        }}
      />
    </>
  );
};

DatePicker.propTypes = {
  dates: PropTypes.shape({}),
  setDatesDayjs: PropTypes.func,
  setDateRange: PropTypes.func,
  setDates: PropTypes.func,
};

const mapStateToProps = (state) => ({
  dates: state.dates,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDatesDayjs: setDatesDayjsAction,
      setDateRange: setDateRangeAction,
      setDates: setDatesAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
