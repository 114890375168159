import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    magicLinkSuccessMobile: {
      fontSize: '15px',

      maxWidth: '924px',
      margin: '0 auto 0',
      width: '100%',
      gap: '20px',
      display: 'flex',

      flexDirection: 'column',
      backgroundColor: 'transparent',
      border: 'none',
      padding: '0',
      height: '100%',
      alignItems: 'center',
    },
    title: {
      fontSize: '44px',
      fontWeight: '500',
      lineHeight: '1.2',
      margin: 0,
      padding: 0,
      marginTop: '-12px', // Bringing it up a tad makes it look better
      maxWidth: '100%',
    },
    resend: {
      width: '100%',
      display: 'flex',
      gap: '20px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    imageContainer: {
      width: '100%',
      height: '400px',
      backgroundColor: '#F0EBCC',
      borderRadius: '24px',
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    footer: {
      width: '100%',
      marginTop: '40px',
      display: 'flex',
      gap: '20px',
      flexDirection: 'column',
    },
  }),
  { name: 'MagicLinkSuccessMobile' },
);

export default useStyles;
