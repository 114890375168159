import React, { useEffect, useRef, useState } from 'react';
import { Button, Box } from '@eatclub-apps/ec-component-library';
import { useHistory } from 'react-router-dom';
import useStyles from './CustomSignInStyles';
import { AppLogo } from '../../../constants/AppLogo';
import { useQuery } from '../../../utils';
import { isEmpty } from '../../../utils/helpers';
import EmailLogin from './EmailLogin';
import MagicLinkLogin from './MagicLinkLogin';

// TODO if fb marketplace it probably needs to default to the password page
const CustomSignIn = ({ authSignedIn }) => {
  const history = useHistory();
  const classes = useStyles();

  const queryRef = useRef(useQuery());
  const params = queryRef.current;

  const facebookMarketplaceLogin = window.location.search.includes('fbmarketplace');

  // Which one we're showing right now
  const [screen, setScreen] = useState(facebookMarketplaceLogin ? 'password' : 'email'); // email, emailSuccess, password

  // force home page on log in
  useEffect(() => {
    // Bypass if we're in the middle of an sso login
    // const params = new URLSearchParams(window.location.search);
    const ssoToken = params.get('ssoToken');
    if (ssoToken) {
      return;
    }

    // Workaround for facebook integration - redirect to prod
    if (
      import.meta.env.VITE_MODE !== 'production' &&
      window.location.href.includes('fbmarketplace')
    ) {
      window.location.href =
        import.meta.env.VITE_PRODUCTION_URL + document.location.pathname + document.location.search;
      return;
    }

    // failsafe if staff mode lingered in partner login
    if (localStorage.getItem('mode') === 'staff') {
      localStorage.removeItem('mode');

      // reload for the authenticator to use the correct user pool config
      window.location.reload();
      return;
    }

    // Remove last part of url, and redirect back to it on login. Include query params
    // NOTE: redirectTo is only for internal routes
    const { pathname } = window.location;

    // extract URL to redirect to after login
    if (
      !isEmpty(pathname) &&
      pathname !== '/' &&
      pathname !== '/login' &&
      pathname !== '/logout' &&
      pathname !== '?' &&
      !params.get('redirectTo')
    ) {
      const redirectUri =
        pathname + (params.toString() ? encodeURIComponent(`?${params.toString()}`) : '');

      // Retain some params, like origin, while adding the redirect uri
      const search = `?${
        params.get('origin') ? `origin=${params.get('origin')}&` : ''
      }redirectTo=${redirectUri}`;

      history.replace({
        pathname: '/',
        search,
      });
    } else {
      // // Show /login in the url. Note that this does not navigate, it only changes the URL in the address bar and history
      // if (pathname === '/login') {
      //   // Keep any params
      //   const paramsString = params.toString();
      //
      //   history.replace({
      //     pathname: '/',
      //     search: paramsString ? `?${paramsString}` : null,
      //   });
      // }
    }
  }, []);

  // Set title and header
  useEffect(() => {
    const pageTitle = `Login | ${import.meta.env.VITE_WEBSITE_NAME} Partners`;
    window.document.title = pageTitle;
  }, []);

  // Scroll to top when changing screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [screen]);

  // Should redirect automatically
  if (authSignedIn) {
    return null;
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <Box
          className={classes.headerLogo}
          // to={'/login'}
          // reloadDocument
          onClick={() => {
            // TODO use reloadDocument in router Link after upgrading react-router to v6
            history.push('/');
            window.location.reload();
          }}
        >
          <AppLogo style={{ color: '#313131', width: '100%' }} />
        </Box>

        <Button
          type='secondary'
          href='mailto:hello@eatclub.com.au'
          style={{
            button: {
              backgroundColor: 'transparent',
              fontSize: '15px',
              padding: '0 32px',
              fontWeight: '400',
            },
          }}
        >
          Get support
        </Button>
      </Box>

      <Box className={classes.signInWrapper}>
        {screen === 'email' && (
          <MagicLinkLogin
            switchLoginType={() => {
              setScreen('password');
            }}
          />
        )}

        {screen === 'password' && (
          <EmailLogin
            switchLoginType={() => {
              setScreen('email');
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default CustomSignIn;
